import {Chip, Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {DateRangeOutlined} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {useLocalStorage} from "../../../components/useLocalStorage";
import {ENV} from "../../../constants";
import {
    AllShiftStatusList,
    facilityConfirmation,
    OpenShiftsStatusList,
    pendingShiftsStatusList,
    salaryCreditTypesForFilters,
    timesheet,
    timeTypesForFilters,
    pendingConcern, HcpPaymentMode, requestedByMenu, requirementTypeList, awaitingShiftsStatusList
} from "../../../constants/data";
import {CommonService, localStore} from "../../../helpers";
import "./ShiftFilter.scss";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

export interface ShiftFilterProps {
    hcpTypes: any;
    facilityList: any;
    noStatus?: boolean;
    //type of shifts
    isMaster?: boolean;
    requestedBy?: any;
    setRequestedBy?: any;
    shiftRequirementType?: any;
    setRequirementType?: any;
    isCompleted?: boolean;
    isOpenShift?: boolean;
    isInProgress?: boolean;
    isClosed?: boolean;
    isPending?: boolean;
    isCancelled?: boolean;
    isApproved?: boolean;
    resetFilters: any;
    isPendingConcernForClosedShift?: any;
    setIsPendingConcernForClosedShift?: any;
    regions?: any;
    selectedRegion?: any;
    setSelectedRegion?: any;
    selectedHcps?: any;
    setSelectedHcps?: any;
    selectedTimeTypes?: any;
    selectedStatusTypes?: any;
    setSelectedTimeTypes?: any;
    selectedFacilities?: any;
    setSelectedFacilities?: any;
    setSelectedApprovedHcps: any;
    setSelectedStatusTypes?: any;
    isTimeSheetUploaded?: any;
    isFacilityApproved?: any;
    isHcpSentHome?: any;
    setIsTimeSheetUploaded?: any;
    setIsFacilityApproved?: any;
    setIsHcpSentHome?: any;
    isPendingConcern?: any;
    setIsPendingConcern?: any;
    hcpPaymentModeValueForClosedShift?: any;
    setHcpPaymentModeValueForClosedShifts?: any;
    selectedSalaryCreditTypes?: any;
    setSelectedSalaryCreditTypes?: any;
    hcpPaymentModeValue?: any;
    setHcpPaymentModeValue?: any;
    setSelectedShifts?: any;
    selectedDates?: any;
    setSelectedDates?: any;
    selectedApprovedHcps: any;
    setIsHcpPayment?: any;
    isHcpPayment?: any;
    setIsFacilityPayment?: any;
    isFacilityPayment?: any;
    setIsShowShiftRate?: any;
    isShowShiftRate?: any;

    dateRange: any;
    paymentDateRange?: any;
    setPaymentDateRange?: any;
    setDateRange: any;
    isFacilityListLoading: boolean;

    resetSelectedCount?: () => void;
    isAwaiting?: boolean;
}

const region_groups = [
    {
        name: "REG1",
        code: "reg1",
        regions: [
            {
                "name": "US - Los Angeles",
                "code": "US - Los Angeles"
            },
            {
                "name": "US - Lancaster",
                "code": "US - Lancaster"
            },
            {
                "name": "US - Palm Springs",
                "code": "US - Palm Springs"
            },
        ]
    },
    {
        name: "REG2",
        code: "reg2",
        regions: [
            {
                "name": "US - San Francisco",
                "code": "US - San Francisco",
            },
            {
                "name": "US - Sacramento",
                "code": "US - Sacramento"
            },
            {
                "name": "US - San Diego",
                "code": "US - San Diego"
            },
            {
                "name": "US - Central Valley",
                "code": "US - Central Valley"
            },
        ]
    },
    {
        name: "REG3",
        code: "reg3",
        regions: [
            {
                "name": "US - Pennsylvania",
                "code": "US - Pennsylvania",
            },
            {
                "name": "US - New Jersey",
                "code": "US - New Jersey"
            },
            {
                "name": "US - Maine",
                "code": "US - Maine"
            },
            {
                "name": "US - New Hampshire",
                "code": "US - New Hampshire"
            },
        ]
    }
];


const ShiftFilter = (props: PropsWithChildren<ShiftFilterProps>) => {
    const [approvedHcps, setApprovedHcps] = useState([]);
    const [isApprovedHcpsLoading, setisApprovedHcpsLoading] = useState(false);
    //states for select all
    const [isAllHcpTypeSelected, setIsAllHcpTypeSelected] = useLocalStorage('isAllHcpTypeSelected', false);
    const [isAllTimeTypesSelected, setisAllTimeTypesSelected] = useLocalStorage('isAllTimeTypesSelected', false);
    const [isAllRegionsSelected, setIsAllRegionsSelected] = useLocalStorage('isAllRegionsSelected', false);
    const [isAllFacilitiesSelected, setIsAllFacilitiesSelected] = useLocalStorage('isAllFacilitiesSelected', false);
    const [isAllStatusTypesSelected, setIsAllStatusTypesSelected] = useLocalStorage('isAllStatusTypesSelected', false);
    const [isAllApprovedHcpsSelected, setIsAllApprovedHcpsSelected] = useLocalStorage('isAllApprovedHcpsSelected', false);
    const [isAllSalaryCreditTypeSelected, setIsAllSalaryCreditTypeSelected] = useLocalStorage('isAllSalaryCreditTypeSelected', false);
    const [selectedRegionGroups, setSelectedRegionGroups] = useState<any>([]);
    const [regionGroups, setRegionGroups] = useState<any>([]);
    const selectedRegion = props?.selectedRegion;
    const setSelectedRegion = props?.setSelectedRegion;
    const statusList = props?.isMaster ? AllShiftStatusList : props.isPending ? (props?.isAwaiting ? awaitingShiftsStatusList : pendingShiftsStatusList) : OpenShiftsStatusList;
    //shift status types
    const isMaster = props?.isMaster;
    const isCompleted = props?.isCompleted;
    const isClosed = props?.isClosed;
    const isPending = props?.isPending;
    const isCancelled = props?.isCancelled;
    const isApproved = props?.isApproved;
    const isInProgress = props?.isInProgress;
    const isOpenShift = props?.isOpenShift;
    const requestedBy = props?.requestedBy;
    const shiftRequirementType = props?.shiftRequirementType;
    const isPendingConcernForClosedShift = props?.isPendingConcernForClosedShift;
    const setIsPendingConcernForClosedShift = props?.setIsPendingConcernForClosedShift;


    //show filter fields
    const SHOW_SALARY_CREDIT_FIELD_FOR_SHIFT_STATUS_TYPES = isCompleted || isClosed || isMaster || isCancelled || isApproved || isInProgress;
    const SHOW_IS_HCP_SENT_HOME_FIELD_FOR_SHIFT_STATUS_TYPES = isCompleted || isClosed || isMaster || isCancelled;

    const regions: any[] = props?.regions ? props?.regions : [];
    const facilityList: any[] = props?.facilityList
        ? props?.facilityList.map((item: any) => ({code: item?._id, name: item?.name}))
        : [];

    const hcpTypes: any[] = props?.hcpTypes ? props?.hcpTypes : [];

    const resetFilters = props?.resetFilters;
    const isPendingConcern = props?.isPendingConcern;
    const hcpPaymentModeValue = props?.hcpPaymentModeValue;
    const hcpPaymentModeValueForClosedShifts = props?.hcpPaymentModeValueForClosedShift;
    const setHcpPaymentModeValueForClosedShifts = props?.setHcpPaymentModeValueForClosedShifts;
    const setHcpPaymentValue = props?.setHcpPaymentModeValue;
    const setIsPendingConcern = props?.setIsPendingConcern;
    const selectedApprovedHcps = props?.selectedApprovedHcps;
    const selectedFacilities = props?.selectedFacilities;
    const selectedHcps = props?.selectedHcps;
    const setSelectedHcps = props?.setSelectedHcps;
    const selectedStatusTypes = props?.selectedStatusTypes;
    const selectedTimeTypes = props?.selectedTimeTypes;
    const setSelectedTimeTypes = props?.setSelectedTimeTypes;
    const selectedSalaryCreditTypes = props?.selectedSalaryCreditTypes;
    const setSelectedSalaryCreditTypes = props?.setSelectedSalaryCreditTypes;
    const setSelectedStatusTypes = props?.setSelectedStatusTypes;
    const setSelectedFacilities = props?.setSelectedFacilities;
    const setSelectedApprovedHcps = props?.setSelectedApprovedHcps;

    const setIsTimeSheetUploaded = props?.setIsTimeSheetUploaded;
    const setIsFacilityApproved = props?.setIsFacilityApproved;
    const setIsHcpSentHome = props?.setIsHcpSentHome;
    const isTimeSheetUploaded = props?.isTimeSheetUploaded;
    const isFacilityApproved = props?.isFacilityApproved;
    const isHcpSentHome = props?.isHcpSentHome;


    const dateRange = props?.dateRange;
    const setDateRange = props?.setDateRange;
    const [startDate, endDate] = dateRange;

    const paymentDateRange = props?.paymentDateRange ? props?.paymentDateRange : [null, null];
    const setPaymentDateRange = props?.setPaymentDateRange;
    const [paymentStartDate, paymentEndDate] = paymentDateRange;

    const setIsHcpPayment = props?.setIsHcpPayment;
    const isHcpPayment = props?.isHcpPayment;

    const setIsFacilityPayment = props?.setIsFacilityPayment;
    const isFacilityPayment = props?.isFacilityPayment;

    const setIsShowShiftRate = props?.setIsShowShiftRate;
    const isShowShiftRate = props?.isShowShiftRate;

    const isFacilityListLoading = props?.isFacilityListLoading;
    const setRequestedBy = props?.setRequestedBy
    const setRequirementType = props?.setRequirementType;


    useEffect(() => {
        const regionsList: any = [];
        region_groups.forEach((region_group) => {
            regionsList.push(region_group);
        });
        setRegionGroups(regionsList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleRegionDelete = (chip: any) => {
        let filterdChips = selectedRegion.filter((item: any) => item !== chip);
        setSelectedRegion(filterdChips);
        selectedRegionGroups.forEach((rgroup: any) => {
            rgroup.regions.forEach((item: any) => {
                if (item.code === chip) {
                    const filtered = selectedRegionGroups.filter((reg: any) => reg.code !== rgroup.code);
                    setSelectedRegionGroups(filtered);
                }
            });
        });
    };

    const handleGroupRegionDelete = useCallback((chip: any) => {
        console.log(chip);
        let filteredChips = selectedRegionGroups.filter((item: any) => item.name !== chip);
        setSelectedRegionGroups(filteredChips);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegionGroups]);

    const handleFacilityDelete = (chip: any) => {
        let filterdChips = selectedFacilities?.filter((item: any) => item?.code !== chip);
        setSelectedFacilities(filterdChips);
    };

    const handleApprovedHcpsDelete = (chip: any) => {
        let filterdChips = selectedApprovedHcps?.filter((item: any) => item?.code !== chip);
        setSelectedApprovedHcps(filterdChips);
    };

    const handleSalaryCreditTypeDelete = (chip: any) => {
        let filterdChips = selectedSalaryCreditTypes?.filter((item: any) => item?.code !== chip);
        setSelectedSalaryCreditTypes(filterdChips);
    };

    const handleHcpDelete = (chip: any) => {
        if (props?.resetSelectedCount) {
            props?.resetSelectedCount();
        }
        let filterdChips = selectedHcps?.filter((item: any) => item !== chip);
        setSelectedHcps(filterdChips);
    };


    const handleStatusDeleteInMaster = (chip: any) => {
        let filterdChips = selectedStatusTypes?.filter((item: any) => item.code !== chip);
        setSelectedStatusTypes(filterdChips);
    };

    const handleStatusDeleteInPending = (chip: any) => {
        let filterdChips = selectedStatusTypes?.filter((item: any) => item !== chip);
        setSelectedStatusTypes(filterdChips);
    };

    const handleStatusDeleteInOpenShifts = (chip: any) => {
        let filterdChips = selectedStatusTypes?.filter((item: any) => item !== chip);
        setSelectedStatusTypes(filterdChips);
    };

    const handleTimeTypeDelete = (chip: any) => {
        let filterdChips = selectedTimeTypes?.filter((item: any) => item !== chip);
        setSelectedTimeTypes(filterdChips);
    };

    const formatFilterMenuList = useCallback((options: any[], isAllMenuItemSelected = false): any[] => {
        let menuList = options?.map((option: any) => option?.code);

        if (isAllMenuItemSelected) {
            menuList = ["Clear All", ...menuList];
        }
        if (!isAllMenuItemSelected) {
            menuList = ["Select All", ...menuList];
        }
        return menuList;
    }, []);

    const formatFilterMenuListToHandleObj = useCallback((options: any[], isAllMenuItemSelected = false): any[] => {
        let menuList = options;
        //  console.log(options);
        const allOptions = options.filter((item) => {
            return item.code !== "Select All" && item.code !== "Clear All";
        });

        if (isAllMenuItemSelected) {
            menuList = [{name: "Clear All", code: "Clear All"}, ...allOptions];
        }

        if (!isAllMenuItemSelected) {
            menuList = [{name: "Select All", code: "Select All"}, ...allOptions];
        }

        return menuList;
    }, []);

    const resetFieldsClearAllOptionToDefault = () => {
        setIsAllHcpTypeSelected(false);
        setisAllTimeTypesSelected(false);
        setIsAllRegionsSelected(false);
        setIsAllFacilitiesSelected(false);
        setIsAllStatusTypesSelected(false);
        setIsAllApprovedHcpsSelected(false);
        setIsAllSalaryCreditTypeSelected(false);
        setSelectedRegionGroups([]);
    };

    const resetSelectedFieldClearAllBasedOnLength = useCallback(() => {
        if (selectedHcps?.length === 0) {
            setIsAllHcpTypeSelected(false);
        }
        if (selectedTimeTypes?.length === 0) {
            setisAllTimeTypesSelected(false);
        }
        if (selectedRegion?.length === 0) {
            setIsAllRegionsSelected(false);
        }
        if (selectedFacilities?.length === 0) {
            setIsAllFacilitiesSelected(false);
        }
        if (selectedStatusTypes?.length === 0) {
            setIsAllStatusTypesSelected(false);
        }
        if (selectedApprovedHcps?.length === 0) {
            setIsAllApprovedHcpsSelected(false);
        }
        if (selectedSalaryCreditTypes?.length === 0) {
            setIsAllSalaryCreditTypeSelected(false);
        }
    }, [selectedHcps, selectedTimeTypes, selectedRegion, selectedFacilities, selectedStatusTypes, selectedApprovedHcps, selectedSalaryCreditTypes, setIsAllHcpTypeSelected, setisAllTimeTypesSelected, setIsAllRegionsSelected, setIsAllFacilitiesSelected, setIsAllStatusTypesSelected, setIsAllApprovedHcpsSelected, setIsAllSalaryCreditTypeSelected]);

    const handleFilterValueSelect = useCallback(
        (newValues: any, options: any[], setSelectedFilterField: any, setIsAllMenuItemsSelected: any, event: any) => {

            selectedRegionGroups.forEach((rgroup: any) => {
                rgroup.regions.forEach((region: any) => {
                    if (event.target.ariaSelected && region.name === event.target.innerHTML) {
                        let filteredChips = selectedRegionGroups.filter((item: any) => item.name !== rgroup.name);
                        setSelectedRegionGroups(filteredChips);
                    }
                });
            });

            if (newValues.length === 0) {
                setIsAllMenuItemsSelected(false);
            }

            if (newValues.includes("Select All") || newValues.includes("Clear All")) {


                if (newValues.includes("Select All")) {
                    setIsAllMenuItemsSelected(true);
                    const allOptions = options.map((item: any) => item.code);
                    setSelectedFilterField(allOptions);
                }

                if (newValues.includes("Clear All")) {
                    setIsAllMenuItemsSelected(false);
                    setSelectedFilterField([]);
                    setSelectedRegionGroups([]);
                }


                return;
            }


            //for single select
            setSelectedFilterField(newValues);
        },
        [selectedRegionGroups]
    );

    const handleFilterValueSelectToHandleObj = useCallback(
        (newValues: any, options: any[], setSelectedFilterField: any, setIsAllMenuItemsSelected: any) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            console.log(newValues);
            if (newValues.length === 0) {
                setIsAllMenuItemsSelected(false);
            }

            if (isSelectAll || isClearAll) {
                if (isSelectAll) {
                    setIsAllMenuItemsSelected(true);
                    const allOptions = options.filter((item) => {
                        return item.code !== "Select All" && item.code !== "Clear All";
                    });
                    setSelectedFilterField(allOptions);
                }

                if (isClearAll) {
                    setIsAllMenuItemsSelected(false);
                    setSelectedFilterField([]);
                }


                return;
            }

            //for single select
            setSelectedFilterField(newValues);
        },
        []
    );

    useEffect(() => {
        console.log(selectedRegionGroups);
        const region_list: any = [];
        selectedRegionGroups.forEach((region_group: any) => {
            region_group.regions.forEach((item: any) => {
                region_list.push(item.name);
            });
        });
        setSelectedRegion((prv: any) => [...region_list]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegionGroups]);

    //fixed fields
    let checkDefaultFields =
        selectedRegion?.length > 0 ||
        selectedFacilities?.length > 0 ||
        selectedApprovedHcps?.length > 0 ||
        selectedHcps?.length > 0 ||
        selectedTimeTypes?.length > 0 ||
        selectedStatusTypes?.length > 0 ||
        dateRange[0] !== null ||
        dateRange[1] !== null;

    //non fixed fields
    let checkForTimeSheetAndFacilityConfirmation =
        (isFacilityApproved !== "" && isFacilityApproved !== null) ||
        (isTimeSheetUploaded !== "" && isTimeSheetUploaded !== null);
    let checkForPendingConcern = isPendingConcern !== "";
    let checkForHcpPaymentMode = hcpPaymentModeValue !== "";
    let checkForIsHcpPayment = isHcpPayment !== "";
    let checkForPaymentDateRange = paymentDateRange[0] !== null || paymentDateRange[1] !== null;
    let checkForIsFacilityPayment = isFacilityPayment !== "";
    let checkForIsShiftRate = isShowShiftRate !== "";
    let checkForIsHcpSentHome = isHcpSentHome !== "";
    let checkForSelectedSalaryCreditTypes = selectedSalaryCreditTypes?.length > 0;
    let checkForRequestedBy = requestedBy !== "";
    let checkForShiftRequirementType = shiftRequirementType !== "";

    const handleIsAllFiltersCleared = (): boolean => {
        let checkDefaultAndMasterFields = checkDefaultFields || checkForIsFacilityPayment || checkForIsHcpSentHome || checkForSelectedSalaryCreditTypes || checkForTimeSheetAndFacilityConfirmation || checkForShiftRequirementType;
        let checkDefaultAndPendingFields = checkDefaultFields || checkForShiftRequirementType;
        let checkDefaultAndApprovedFields = checkDefaultFields || checkForSelectedSalaryCreditTypes || checkForShiftRequirementType;
        let checkDefaultAndInProgressFields = checkDefaultFields || checkForSelectedSalaryCreditTypes || checkForShiftRequirementType;
        let checkDefaultAndCompletedFields = checkDefaultFields || checkForTimeSheetAndFacilityConfirmation || checkForIsHcpSentHome || checkForSelectedSalaryCreditTypes || checkForPendingConcern || checkForHcpPaymentMode || checkForShiftRequirementType;
        let checkDefaultAndClosedFields = checkDefaultFields || checkForIsHcpPayment || checkForPaymentDateRange || checkForIsHcpSentHome || checkForSelectedSalaryCreditTypes || checkForPendingConcern || checkForHcpPaymentMode || checkForShiftRequirementType;
        let checkDefaultAndCancelledFields = checkDefaultFields || checkForIsHcpSentHome || checkForSelectedSalaryCreditTypes || checkForShiftRequirementType;
        let checkDefaultAndOpenShiftFields = checkDefaultFields || checkForIsShiftRate || checkForRequestedBy || checkForShiftRequirementType || checkForShiftRequirementType;
        // setSelectedRegionGroups([])
        let isCondition: boolean;
        if (isCompleted) {
            isCondition = checkDefaultAndCompletedFields;
        } else if (isClosed) {
            isCondition = checkDefaultAndClosedFields;
        } else if (isPending) {
            isCondition = checkDefaultAndPendingFields;
        } else if (isMaster) {
            isCondition = checkDefaultAndMasterFields;
        } else if (isOpenShift) {
            isCondition = checkDefaultAndOpenShiftFields;
        } else if (isCancelled) {
            isCondition = checkDefaultAndCancelledFields;
        } else if (isApproved) {
            isCondition = checkDefaultAndApprovedFields;
        } else if (isInProgress) {
            isCondition = checkDefaultAndInProgressFields;
        } else {
            isCondition = checkDefaultFields;
        }

        if (isCondition) {
            return false;
        } else {
            return true;
        }
    };

    const getAllApprovedHcps = () => {
        setisApprovedHcpsLoading(true);
        let payload: any = {
            is_approved: true,
        };
        if (selectedSalaryCreditTypes?.length > 0) {
            payload.salary_credit = selectedSalaryCreditTypes.map((item: any) => item?.code);
        }
        CommonService._api
            .post(ENV.API_URL + "hcp/lite", payload)
            .then((resp) => {
                const approvedHcps = resp?.data?.map((item: any) => ({
                    name: `${item?.first_name} ${item?.last_name}`,
                    code: item?.user_id,
                }));
                setApprovedHcps(approvedHcps || []);
                setisApprovedHcpsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setisApprovedHcpsLoading(false);
            });
    };

    useEffect(() => {
        getAllApprovedHcps();
        // eslint-disable-next-line
    }, [selectedSalaryCreditTypes]);

    useEffect(() => {
        resetSelectedFieldClearAllBasedOnLength();
    }, [resetSelectedFieldClearAllBasedOnLength]);

    const handleRegionGroupSelection = useCallback((rgroups: any) => {
        const newlySelectedGroups = [];
        //   const newlyDeSelectedGroups = [];
        setSelectedRegionGroups((oldState: any) => {
            rgroups.forEach((rgroup: any) => {
                const index = oldState.findIndex((item: any) => item.code === rgroup.code);
                if (index > -1) {

                } else {
                    newlySelectedGroups.push(rgroup);
                }
            });
            return rgroups;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="shift-filters mrg-bottom-20">
            <div className="form-field-wrapper">
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_hcps"
                        value={selectedHcps}
                        options={formatFilterMenuList(hcpTypes, isAllHcpTypeSelected)}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(newValue, hcpTypes, setSelectedHcps, setIsAllHcpTypeSelected, e)
                        }
                        renderTags={() => null}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="HCP Types"/>}
                        disableCloseOnSelect
                    />
                </div>

                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_time_types"
                        renderTags={() => null}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Shift Types"/>}
                        disableCloseOnSelect
                        value={selectedTimeTypes}
                        options={formatFilterMenuList(timeTypesForFilters, isAllTimeTypesSelected)}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(newValue, timeTypesForFilters, setSelectedTimeTypes, setisAllTimeTypesSelected, e)
                        }
                    />
                </div>
                {isOpenShift && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                className="mrg-top-10 "
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                id="input_select_status"
                                value={selectedStatusTypes}
                                options={formatFilterMenuList(statusList, isAllStatusTypesSelected)}
                                onChange={(e, newValue) =>
                                    handleFilterValueSelect(newValue, statusList, setSelectedStatusTypes, setIsAllStatusTypesSelected, e)
                                }
                                renderTags={() => null}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                                                    placeholder="Status"/>}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper
                                    style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={requestedBy}
                                options={requestedByMenu}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Time Sheet"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setRequestedBy(value);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} id="select_requested_by" variant="outlined"
                                               placeholder={"Requested By"}/>
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                    </>

                )}


                {isPending && (
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            className="mrg-top-10 "
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            id="input_select_status"
                            value={selectedStatusTypes}
                            options={formatFilterMenuList(statusList, isAllStatusTypesSelected)}
                            onChange={(e, newValue) =>
                                handleFilterValueSelect(newValue, statusList, setSelectedStatusTypes, setIsAllStatusTypesSelected, e)
                            }
                            renderTags={() => null}
                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Status"/>}
                            disableCloseOnSelect
                        />
                    </div>
                )}

                {isMaster && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                className="mrg-top-10"
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                id="input_select_status"
                                value={selectedStatusTypes}
                                getOptionLabel={(option) => option?.name}
                                getOptionSelected={(option, value) => option.name === value?.name}
                                options={formatFilterMenuListToHandleObj(statusList, isAllStatusTypesSelected)}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        handleFilterValueSelectToHandleObj(
                                            newValue,
                                            statusList,
                                            setSelectedStatusTypes,
                                            setIsAllStatusTypesSelected
                                        );
                                    }
                                }}
                                renderTags={() => null}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                                                    placeholder="Status"/>}
                                disableCloseOnSelect
                            />
                        </div>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={isFacilityPayment}
                                options={timesheet}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Billed"}
                                id="input_select_fac_payment_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setIsFacilityPayment(value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_payment_status"
                                        variant="outlined"
                                        placeholder={"Facility Billed"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                        <>
                            <div className="form-field-item">
                                <Autocomplete
                                    disableClearable
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    value={isTimeSheetUploaded}
                                    options={timesheet}
                                    getOptionLabel={(option: any) => option.label}
                                    placeholder={"Time Sheet"}
                                    id="input_select_status"
                                    className="mrg-top-10"
                                    onChange={($event, value) => {
                                        setIsTimeSheetUploaded(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} id="select_status" variant="outlined"
                                                   placeholder={"Time Sheet"}/>
                                    )}
                                    disableCloseOnSelect
                                />
                            </div>

                            <div className="form-field-item">
                                <Autocomplete
                                    disableClearable
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    value={isFacilityApproved}
                                    options={facilityConfirmation}
                                    getOptionLabel={(option: any) => option.label}
                                    placeholder={"Facility Confirmation"}
                                    id="input_select_status"
                                    className="mrg-top-10"
                                    onChange={($event, value) => {
                                        setIsFacilityApproved(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="select_fac_confirm"
                                            variant="outlined"
                                            placeholder={"Facility Confirmation"}
                                        />
                                    )}
                                    disableCloseOnSelect
                                />
                            </div>
                        </>
                    </>
                )}

                {isOpenShift && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={isShowShiftRate}
                                options={timesheet}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Show Shift Rate"}
                                id="input_select_show_shift_rate"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    if (value) {
                                        setIsShowShiftRate(value);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="input_select_show_shift_rate_val"
                                        variant="outlined"
                                        placeholder={"Show Shift Rate"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                    </>
                )}

                {isCompleted && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={isTimeSheetUploaded}
                                options={timesheet}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Time Sheet"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setIsTimeSheetUploaded(value);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} id="select_status" variant="outlined"
                                               placeholder={"Time Sheet"}/>
                                )}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={isFacilityApproved}
                                options={facilityConfirmation}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setIsFacilityApproved(value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Facility Confirmation"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={isPendingConcern}
                                options={pendingConcern}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setIsPendingConcern(value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Pending Concern"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={hcpPaymentModeValue}
                                options={HcpPaymentMode}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setHcpPaymentValue(value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Staff Payment Mode"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                    </>
                )}

                {
                    SHOW_IS_HCP_SENT_HOME_FIELD_FOR_SHIFT_STATUS_TYPES && <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            value={isHcpSentHome}
                            options={facilityConfirmation}
                            getOptionLabel={(option: any) => option.label}
                            placeholder={"Staff Sent Home"}
                            id="input_select_hcp_sent_home"
                            className="mrg-top-10"
                            onChange={($event, value) => {
                                setIsHcpSentHome(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="select_hcp_sent_home"
                                    variant="outlined"
                                    placeholder={"Staff Sent Home"}
                                />
                            )}
                            disableCloseOnSelect
                        />
                    </div>
                }

                {SHOW_SALARY_CREDIT_FIELD_FOR_SHIFT_STATUS_TYPES && (
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            className="mrg-top-10"
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            id="input_select_salary_credit"
                            renderTags={() => null}
                            renderInput={(params) => <TextField {...params} variant="outlined"
                                                                placeholder="Salary Credit Day"/>}
                            disableCloseOnSelect

                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option.name === value?.name}

                            value={selectedSalaryCreditTypes}
                            options={formatFilterMenuListToHandleObj(salaryCreditTypesForFilters, isAllSalaryCreditTypeSelected)}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    handleFilterValueSelectToHandleObj(newValue, salaryCreditTypesForFilters, setSelectedSalaryCreditTypes, setIsAllSalaryCreditTypeSelected);
                                }
                            }
                            }
                        />
                    </div>
                )}

                {isClosed && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={isHcpPayment}
                                options={timesheet}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"HCP Paid"}
                                id="input_select_Payment_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setIsHcpPayment(value);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} id="select_payment_status" variant="outlined"
                                               placeholder={"HCP Paid"}/>
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={hcpPaymentModeValueForClosedShifts}
                                options={HcpPaymentMode}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setHcpPaymentModeValueForClosedShifts(value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Staff Payment Mode"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={isPendingConcernForClosedShift}
                                options={pendingConcern}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setIsPendingConcernForClosedShift(value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Pending Concern"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="mrg-top-10">
                            <label className="label-wrapper">
                                <DatePicker
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Payment Date"
                                    className="custom-input"
                                    selectsRange={true}
                                    startDate={paymentStartDate && new Date(paymentStartDate)}
                                    endDate={paymentEndDate && new Date(paymentEndDate)}
                                    onChange={(update) => {
                                        setPaymentDateRange(update);
                                    }}
                                    isClearable={true}
                                />
                                {!paymentDateRange[0] && !paymentDateRange[1] && (
                                    <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>
                                )}
                            </label>
                        </div>
                    </>
                )}
                <div className="mrg-top-10">
                    <label className="label-wrapper">
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select Date"
                            className="custom-input"
                            selectsRange={true}
                            startDate={startDate && new Date(startDate)}
                            endDate={endDate && new Date(endDate)}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        {!dateRange[0] && !dateRange[1] && (
                            <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>
                        )}
                    </label>
                </div>
            </div>
            <div className="form-field-wrapper">
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        multiple
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        placeholder={"Region"}
                        id="input_select_regions"
                        className="mrg-top-10"
                        value={selectedRegion}
                        options={formatFilterMenuList(regions, isAllRegionsSelected)}
                        onChange={(e, newValue) => {
                            console.log(newValue);
                            if (newValue) {
                                handleFilterValueSelect(newValue, regions, setSelectedRegion, setIsAllRegionsSelected, e);
                            }
                        }}
                        renderTags={() => null}
                        renderInput={(params) => (
                            <TextField {...params} id="select_region" variant="outlined" placeholder="Region"/>
                        )}
                        disableCloseOnSelect
                    />
                </div>
                <div className="form-field-item">
                    <Autocomplete
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        options={regionGroups}
                        value={selectedRegionGroups}
                        getOptionLabel={(option: any) => option.name}
                        getOptionSelected={(option, value) => {
                            return option.name === value?.name;
                        }}
                        id="input_select_region"
                        className="mrg-top-10 "
                        onChange={($event, value) => {
                            console.log(value);
                            handleRegionGroupSelection(value);
                        }}
                        renderTags={() => null}
                        renderInput={(params) => <TextField {...params} id="select_region" variant="outlined"
                                                            placeholder={"Group Region"}/>}
                        disableCloseOnSelect
                    />
                </div>

                <div className="form-field-item">
                    {/*<Autocomplete*/}
                    {/*    disableClearable*/}
                    {/*    PaperComponent={({children}) => <Paper*/}
                    {/*        style={{color: "#1e1e1e"}}>{children}</Paper>}*/}
                    {/*    value={shiftRequirementType}*/}
                    {/*    options={requirementTypeList}*/}
                    {/*    getOptionLabel={(option: any) => option.label}*/}
                    {/*    placeholder={"Time Sheet"}*/}
                    {/*    id="input_select_status"*/}
                    {/*    className="mrg-top-10"*/}
                    {/*    onChange={($event, value) => {*/}
                    {/*        setRequirementType(value);*/}

                    {/*    }}*/}
                    {/*    renderInput={(params) => (*/}
                    {/*        <TextField {...params} id="select_requested_by" variant="outlined"*/}
                    {/*                   placeholder={"Requirement Type"}/>*/}
                    {/*    )}*/}
                    {/*    disableCloseOnSelect*/}
                    {/*/>*/}
                    <div style={{position: "relative"}}>
                        <Autocomplete
                            disableClearable
                            PaperComponent={({children}) => (
                                <Paper style={{color: "#1e1e1e"}}>{children}</Paper>
                            )}
                            value={shiftRequirementType}
                            options={requirementTypeList}
                            getOptionLabel={(option: any) => option.label}
                            id="input_select_status"
                            className="mrg-top-10"
                            onChange={($event, value) => {
                                setRequirementType(value);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder={"Requirement Type"}/>
                            )}
                            disableCloseOnSelect
                        />
                        {shiftRequirementType && (
                            <IconButton
                                onClick={() => setRequirementType(null)}
                                style={{
                                    position: "absolute",
                                    right: 30,
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    padding: 0,
                                }}
                            >
                                <ClearIcon/>
                            </IconButton>
                        )}
                    </div>
                </div>

                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        disabled={isFacilityListLoading}
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        getOptionLabel={(option) => option?.name}
                        getOptionSelected={(option, value) => option.name === value?.name}
                        id="input_select_facility"
                        value={selectedFacilities}
                        options={formatFilterMenuListToHandleObj(facilityList, isAllFacilitiesSelected)}
                        onChange={(e, newValue) => {
                            if (newValue) {
                                handleFilterValueSelectToHandleObj(
                                    newValue,
                                    facilityList,
                                    setSelectedFacilities,
                                    setIsAllFacilitiesSelected
                                );
                            }
                        }}
                        renderTags={() => null}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Facilities"/>}
                        disableCloseOnSelect
                    />
                    {isFacilityListLoading && (
                        <div className="loading-text-wrapper">
                            <span className="loading-text">loading...</span>
                        </div>
                    )}
                </div>


                {!isOpenShift && (
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            disabled={isApprovedHcpsLoading}
                            className="mrg-top-10"
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            id="input_select_hcps"
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option.name === value?.name}
                            value={selectedApprovedHcps}
                            options={formatFilterMenuListToHandleObj(approvedHcps, isAllApprovedHcpsSelected)}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    handleFilterValueSelectToHandleObj(
                                        newValue,
                                        approvedHcps,
                                        setSelectedApprovedHcps,
                                        setIsAllApprovedHcpsSelected
                                    );
                                }
                            }}
                            renderTags={() => null}
                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="HCPs"/>}
                            disableCloseOnSelect
                        />
                        {isApprovedHcpsLoading && (
                            <div className="loading-text-wrapper">
                                <span className="loading-text">loading...</span>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="custom-chips-wrapper">
                {selectedRegion.length > 0 &&
                    selectedRegion.map((reg: any) => {
                        return (
                            <p className="custom-chips" key={reg}>
                                <Chip label={reg} onDelete={() => handleRegionDelete(reg)}/>
                            </p>
                        );
                    })}
                {selectedFacilities && selectedFacilities.length > 0 && (
                    <p className="custom-chips">
                        {selectedFacilities.map((data: any) => (
                            <Chip key={data?.code} label={data?.name}
                                  onDelete={() => handleFacilityDelete(data?.code)}/>
                        ))}
                    </p>
                )}

                {selectedApprovedHcps && selectedApprovedHcps.length > 0 && (
                    <p className="custom-chips">
                        {selectedApprovedHcps.map((data: any) => (
                            <Chip key={data?.code} label={data?.name}
                                  onDelete={() => handleApprovedHcpsDelete(data?.code)}/>
                        ))}
                    </p>
                )}
                {selectedHcps && selectedHcps.length > 0 && (
                    <p className="custom-chips">
                        {selectedHcps.map((data: any) => (
                            <Chip key={data} label={data} onDelete={() => handleHcpDelete(data)}/>
                        ))}
                    </p>
                )}

                {isOpenShift && selectedStatusTypes && selectedStatusTypes.length > 0 && (
                    <p className="custom-chips">
                        {selectedStatusTypes.map((data: any, index: any) => (
                            <Chip key={index} label={data} onDelete={() => handleStatusDeleteInOpenShifts(data)}/>)
                        )}
                    </p>
                )}

                {isPending && selectedStatusTypes && selectedStatusTypes.length > 0 && (
                    <p className="custom-chips">
                        {selectedStatusTypes.map((data: any) => (
                            <Chip key={data} label={data} onDelete={() => handleStatusDeleteInPending(data)}/>
                        ))}
                    </p>
                )}

                {isMaster && selectedStatusTypes && selectedStatusTypes.length > 0 && (
                    <p className="custom-chips">
                        {selectedStatusTypes.map((data: any, index: any) => (<Chip key={index} label={data?.name}
                                                                                   onDelete={() => handleStatusDeleteInMaster(data?.code)}/>)
                        )}
                    </p>
                )}

                {selectedTimeTypes && selectedTimeTypes.length > 0 && (
                    <p className="custom-chips">
                        {selectedTimeTypes.map((data: any) => (
                            <Chip key={data} label={data} onDelete={() => handleTimeTypeDelete(data)}/>
                        ))}
                    </p>
                )}

                {selectedSalaryCreditTypes && selectedSalaryCreditTypes.length > 0 && (
                    <p className="custom-chips">
                        {selectedSalaryCreditTypes.map((data: any) => (
                            <Chip key={data?.code} label={data?.name}
                                  onDelete={() => handleSalaryCreditTypeDelete(data?.code)}/>
                        ))}
                    </p>
                )}
                {selectedRegionGroups && selectedRegionGroups.length > 0 && (
                    <p className="custom-chips">
                        {selectedRegionGroups.map((data: any) => (
                            <Chip key={data?.name} label={data?.name}
                                  onDelete={() => handleGroupRegionDelete(data?.name)}/>
                        ))}
                    </p>
                )}
                <span
                    onClick={() => {
                        resetFilters();
                        resetFieldsClearAllOptionToDefault();
                    }}
                    color="secondary"
                    id="btn_reset_filter"
                    className={`clear-all-filters mrg-top-10  ${handleIsAllFiltersCleared() ? " hide-filter" : "show-filter"}`}
                >
					Clear All Filters
				</span>
            </div>
        </div>
    );
};


const clearFieldsFiltersFromLocalStorage = () => {
    localStore.removeItem("selectedRegion");
    localStore.removeItem("selectedFacilities");
    localStore.removeItem("selectedApprovedHcps");
    localStore.removeItem("selectedHcps");
    localStore.removeItem("statusType");
    localStore.removeItem("selectedStatusTypes");
    localStore.removeItem("selectedStatusTypesInPending");
    localStore.removeItem("selectedStatusTypesInOpenShifts");
    localStore.removeItem("dateRange");
    localStore.removeItem("selectedTimeTypes");
    localStore.removeItem("isFacilityApproved");
    localStore.removeItem("isPendingConcernForCompletedShift");
    localStore.removeItem("hcpPaymentModeValue");
    localStore.removeItem("isTimeSheetUploaded");
    localStore.removeItem("dateRange");
    localStore.removeItem("paymentDateRange");
    localStore.removeItem("isHcpPayment");
    localStore.removeItem("isFacilityPayment");
    localStore.removeItem("isShowShiftRate");
    localStore.removeItem("isHcpSentHome");
    localStore.removeItem("selectedSalaryCreditTypes");
    localStore.removeItem("hcpPaymentModeValueForClosedShift");
    localStore.removeItem("isPendingConcernForClosedShift");
    localStore.removeItem("requested-by-open-shift");
};


const clearFieldsSelectAllOptionFromLocalStorage = () => {
    localStore.removeItem("isAllHcpTypeSelected");
    localStore.removeItem("isAllTimeTypesSelected");
    localStore.removeItem("isAllRegionsSelected");
    localStore.removeItem("isAllFacilitiesSelected");
    localStore.removeItem("isAllStatusTypesSelected");
    localStore.removeItem("isAllApprovedHcpsSelected");
    localStore.removeItem("isAllSalaryCreditTypeSelected");
};


export const clearShiftFilterValues = () => {
    clearFieldsFiltersFromLocalStorage();
    clearFieldsSelectAllOptionFromLocalStorage();
};


export default ShiftFilter;
