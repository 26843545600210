import {Button, Paper} from "@material-ui/core";
import {Field, FieldArray, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import moment from "moment";
import React, {PropsWithChildren, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import LoaderComponent from "../../../../components/LoaderComponent";
import {ENV} from "../../../../constants";
import {ApiService, CommonService} from "../../../../helpers";
import {StateParams} from "../../../../store/reducers";
import "./AddShiftsComponent.scss";
import {shiftsFormValidation} from "./AddShiftsFormValidation";
import {DatePicker} from "formik-material-ui-pickers";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePickers from "react-multi-date-picker";

export interface AddHcpToShiftComponentProps {
    selectedShiftIds: any[];
    confirm: () => void;
    cancel: () => void;
}


const AddShiftsComponent = (props: PropsWithChildren<AddHcpToShiftComponentProps>) => {
    const selectedShiftIds = props?.selectedShiftIds;
    const cancel = props?.cancel;
    const confirm = props?.confirm;
    const [data, setData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hcpPayDate] = useState<null | string>(null);

    const {user} = useSelector((state: StateParams) => state.auth);

    useEffect(() => {
        console.log(data)
    }, [data]);

    const createShiftsInitialState = (shiftArr: any[]) => {
        let initialStateFiltered = shiftArr.map((shift) => {
            const {start_time, end_time} = CommonService.getUtcTimeinHours(
                shift?.expected?.shift_start_time,
                shift?.expected?.shift_end_time
            );
            const start_date = CommonService.getUtcDate(shift?.expected?.shift_start_time);
            const end_date = CommonService.getUtcDate(shift?.expected?.shift_end_time);
            const facility_name = shift?.facility?.name;
            const hcp_first_name = shift?.hcp_data?.first_name;
            const hcp_last_name = shift?.hcp_data?.last_name;
            const differential_amount = shift?.payments?.differential;
            const requirementType = shift?.requirement_type

            return {
                _id: shift?._id,
                start_date: start_date && moment(start_date).format("YYYY-MM-DD"),
                end_date: end_date && moment(end_date).format("YYYY-MM-DD"),
                start_time: start_time,
                end_time: end_time,
                differential_amount: differential_amount,
                facility_name: facility_name,
                hcp_first_name: hcp_first_name,
                hcp_last_name: hcp_last_name,
                requirementType: requirementType
            };
        });

        console.log(initialStateFiltered);

        return {
            requirements: initialStateFiltered,
        };
    };

    const getAllShifts = async () => {
        setIsLoading(true);
        try {
            if (selectedShiftIds.length > 0) {
                let AllShifts = await fetchShiftDetails(selectedShiftIds);
                setData(AllShifts);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const fetchShiftDetails = (applicationIds: any[]) => {
        let payload = {
            application_ids: applicationIds,
        };
        return new Promise((resolve, reject) => {
            CommonService._api
                .post(ENV.API_URL + `application/reqDetails`, payload)
                .then((resp) => {
                    resolve(resp?.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    };

    const formatAllShiftTimingsToSeconds = (shifts: any[]) => {

        const formattedShifts = shifts.map((shift) => {
            console.log(shift?.requirementType)
            if (shift?.requirementType === "home") {
                return {
                    _id: shift?._id,
                    differential_amount: Number(shift?.differential_amount),
                    start_time: CommonService.convertHoursToMinutes(shift.start_time),
                    // start_date: shift?.start_date,
                    end_time: CommonService.convertHoursToMinutes(shift?.end_time),
                    // end_date: shift?.end_date,
                    shift_dates: shift.shift_dates || [shift?.start_date],
                };

            } else if (shift?.requirementType === "contract") {
                return {
                    _id: shift?._id,
                    differential_amount: Number(shift?.differential_amount),
                    start_time: CommonService.convertHoursToMinutes(shift.start_time),
                    // start_date: shift?.start_date,
                    end_time: CommonService.convertHoursToMinutes(shift?.end_time),
                    //   end_date: shift?.end_date,
                    shift_dates: shift.shift_dates || CommonService.generateDateRange(shift?.start_date, shift?.end_date),
                };
            } else {
                return {
                    _id: shift?._id,
                    differential_amount: Number(shift?.differential_amount),
                    start_time: CommonService.convertHoursToMinutes(shift.start_time),
                    start_date: shift?.start_date,
                    end_time: CommonService.convertHoursToMinutes(shift?.end_time),
                    end_date: shift?.end_date,
                };
            }
        });
        return formattedShifts;
    };


    useEffect(() => {
        getAllShifts();
        // eslint-disable-next-line
    }, []);

    const handleSuccessAndFailureOfApproveShifts = (bulkAddPayload: any) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + `shifts`, bulkAddPayload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const onAdd = (shifts: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<any>) => {
        const formattedShifts = formatAllShiftTimingsToSeconds(shifts?.requirements);
        console.log(formattedShifts)
        const formattedShiftsWithHcpPayDate: any = formattedShifts.map((shift: any) => ({
            ...shift,
            hcp_pay_date: hcpPayDate,
        }));

        const payload = {
            applications: formattedShifts,
            approved_by: user._id,
        };

        const bulkAddPayload = {
            applications: formattedShiftsWithHcpPayDate,
            approved_by: user._id,
        };

        CommonService._api
            .post(ENV.API_URL + `application/approve`, payload)
            .then((res: any) => {
                handleSuccessAndFailureOfApproveShifts(bulkAddPayload)
                    .then((res: any) => {
                        setSubmitting(false);
                        CommonService.showToast(res?.msg || "Success", "success");
                        confirm();
                    })
                    .catch((err) => {
                        console.log(err);
                        setSubmitting(false);
                        const formattedError = Object.entries(err?.error);
                        formattedError.map((err: any) => {
                            let hcpName = err[0];
                            let hcpDate = moment(err[1]).format("MM-DD-YYYY");
                            return CommonService.showToast(`${hcpName} already approved for ${hcpDate}"|| "Error"`, "error");
                        });

                    });
            })
            .catch((err) => {
                handleSuccessAndFailureOfApproveShifts(bulkAddPayload)
                    .then((res: any) => {
                        setSubmitting(false);
                        // CommonService.showToast(res?.msg || "Success", "success");
                        confirm();
                    })
                    .catch((err) => {
                        console.log(err);
                        setSubmitting(false);
                        const formattedError = Object.entries(err?.error);
                        formattedError.map((err: any) => {
                            let hcpName = err[0];
                            let hcpDate = moment(err[1]).format("MM-DD-YYYY");
                            return CommonService.showToast(`${hcpName} already approved for ${hcpDate}"|| "Error"`, "error");
                        });
                        return CommonService.showToast("Already approved" || "Error", "error");
                    });

                CommonService.showToast(err?.error || err?.error || "Error", 'error');

                if (err?.error) {
                    if (typeof err?.error === "object") {
                        const formattedError = Object.entries(err?.error);
                        console.log(formattedError);
                        if (formattedError) {
                            formattedError.map((err: any) => {
                                let hcpName = err[0];
                                let hcpDate = moment(err[1]).format("MM-DD-YYYY");
                                return CommonService.showToast(`${hcpName} is already approved for ${hcpDate}` || "Error", "error");
                            });
                        }

                    }
                } else {
                    CommonService.showToast(`${err?.error}` || "Error", "error");
                }

            });
    };

    if (isLoading) {
        return (
            <Paper>
                <div className="pdd-100" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <LoaderComponent position="block"/>
                </div>
            </Paper>
        );
    }


    return (
        !isLoading && (
            <div className="create-shift mrg-10 pdd-10">
                {/*<div className="shift-heading-container">*/}
                {/*    <h2 className="shift-heading">Shift Details</h2>*/}
                {/*</div>*/}
                <div className={'d-flex justify-content-center align-items-center'}>
                </div>
                <Formik
                    initialValues={createShiftsInitialState(data)}
                    validateOnChange={true}
                    validationSchema={shiftsFormValidation}
                    onSubmit={onAdd}
                >
                    {({isSubmitting, setFieldValue, isValid, resetForm, values}) => (
                        <Form>
                            {/*<div className="hcp-pay-date-container mrg-top-20">*/}
                            {/*    <Field*/}
                            {/*        onChange={(e: any) => {*/}
                            {/*            setHcpPayDate(e.target.value);*/}
                            {/*        }}*/}
                            {/*        value={hcpPayDate}*/}
                            {/*        variant="outlined"*/}
                            {/*        name={`hcp_pay_date`}*/}
                            {/*        type="date"*/}
                            {/*        InputLabelProps={{shrink: true}}*/}
                            {/*        component={TextField}*/}
                            {/*        label="HCP Pay Date (MM/DD/YYYY)"*/}
                            {/*        fullWidth*/}
                            {/*    />*/}
                            {/*</div>*/}


                            <FieldArray
                                name="fields"
                                render={({...props}) => (
                                    <div>
                                        {values?.requirements &&
                                            values.requirements.map((shift, index) => {
                                                //    console.log(shift);
                                                // @ts-ignore
                                                return (
                                                    <div className="shift-container" key={index}>

                                                        {(shift?.requirementType !== "home" && shift?.requirementType !== "contract") &&
                                                            <div>
                                                                <h3 onClick={() => {
                                                                    console.log(shift)
                                                                }}>
                                                                    {shift?.hcp_first_name} {shift?.hcp_last_name} (
                                                                    {shift?.facility_name || "N/A"})
                                                                    (Per Diem)
                                                                </h3>
                                                                <div>
                                                                    <div className="input-container mrg-top-10">
                                                                        {/*<Field*/}
                                                                        {/*    variant="outlined"*/}
                                                                        {/*    name={`requirements.${index}.start_date`}*/}
                                                                        {/*    type="date"*/}
                                                                        {/*    InputLabelProps={{shrink: true}}*/}
                                                                        {/*    component={TextField}*/}
                                                                        {/*    label="Start Date* (MM/DD/YYYY)"*/}
                                                                        {/*    fullWidth*/}
                                                                        {/*    disabled={true}*/}
                                                                        {/*    parse={(value: any) => formatDateForInput(value)}*/}
                                                                        {/*/>*/}


                                                                        {/*<Field*/}
                                                                        {/*    disabled={true}*/}
                                                                        {/*    variant="inline"*/}
                                                                        {/*    orientation="landscape"*/}
                                                                        {/*    openTo="date"*/}
                                                                        {/*    format="MM/dd/yyyy"*/}
                                                                        {/*    views={["year", "month", "date"]}*/}
                                                                        {/*    inputVariant="outlined"*/}
                                                                        {/*    component={DatePicker}*/}
                                                                        {/*    placeholder="MM/DD/YYYY" fullWidth*/}
                                                                        {/*    autoComplete="off"*/}
                                                                        {/*    InputLabelProps={{shrink: true}}*/}
                                                                        {/*    label="Start Date* (MM/DD/YYYY)"*/}
                                                                        {/*    name={`requirements.${index}.start_date`}/>*/}

                                                                        <Field
                                                                            component={DatePicker}
                                                                            name={`requirements.${index}.start_date`}
                                                                            label="Start Date* (MM/DD/YYYY)"
                                                                            inputVariant="outlined" // Set input variant for MUI TextField
                                                                            format="MM/dd/yyyy" // Desired display format
                                                                            views={['year', 'month', 'date']} // Allow selection of year, month, and date
                                                                            fullWidth
                                                                            disabled={true} // Disable the date picker
                                                                            InputLabelProps={{shrink: true}}
                                                                            placeholder="MM/DD/YYYY"
                                                                            value={CommonService.getUtcDate(shift?.start_date)}
                                                                        />

                                                                        {/*<Field*/}
                                                                        {/*    variant="outlined"*/}
                                                                        {/*    format="MM/dd/yyyy"*/}
                                                                        {/*    name={`requirements.${index}.start_date`}*/}
                                                                        {/*    type="date"*/}
                                                                        {/*    InputLabelProps={{shrink: true}}*/}
                                                                        {/*    component={TextField}*/}
                                                                        {/*    label="Start Date* (MM/DD/YYYY)"*/}
                                                                        {/*    fullWidth*/}
                                                                        {/*    disabled={true}*/}
                                                                        {/*/>*/}
                                                                        <Field
                                                                            name={`requirements.${index}.start_time`}
                                                                            variant="outlined"
                                                                            type="time"
                                                                            InputLabelProps={{shrink: true}}
                                                                            component={TextField}
                                                                            label="Start Time*"
                                                                            fullWidth
                                                                            disabled={true}
                                                                        />
                                                                        <Field
                                                                            name={`requirements.${index}.differential_amount`}
                                                                            variant="outlined"
                                                                            InputLabelProps={{shrink: true}}
                                                                            type={"text"}
                                                                            component={TextField}
                                                                            label="Differential Amount"
                                                                            fullWidth
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={`input-container${shift?.requirementType === "facility" ? "-minor" : ""} mrg-top-10`}>
                                                                        {/*<Field*/}
                                                                        {/*    name={`requirements.${index}.end_date`}*/}
                                                                        {/*    variant="outlined"*/}
                                                                        {/*    type="date"*/}
                                                                        {/*    InputLabelProps={{shrink: true}}*/}
                                                                        {/*    component={TextField}*/}
                                                                        {/*    label="End Date* (MM/DD/YYYY)"*/}
                                                                        {/*    fullWidth*/}
                                                                        {/*    disabled={true}*/}
                                                                        {/*    parse={(value: any) => formatDateForInput(value)}*/}
                                                                        {/*/>*/}

                                                                        <Field
                                                                            disabled={true}
                                                                            //  disabled={false}
                                                                            variant="inline"
                                                                            orientation="landscape"
                                                                            openTo="date"
                                                                            format="MM/dd/yyyy"
                                                                            views={["year", "month", "date"]}
                                                                            inputVariant="outlined"
                                                                            component={DatePicker}
                                                                            placeholder="MM/DD/YYYY" fullWidth
                                                                            autoComplete="off"
                                                                            InputLabelProps={{shrink: true}}
                                                                            label="End Date* (MM/DD/YYYY)"
                                                                            name={`requirements.${index}.end_date`}
                                                                            value={CommonService.getUtcDate(shift?.end_date)}
                                                                        />
                                                                        <Field
                                                                            name={`requirements.${index}.end_time`}
                                                                            variant="outlined"
                                                                            type="time"
                                                                            InputLabelProps={{shrink: true}}
                                                                            component={TextField}
                                                                            label="End Time*"
                                                                            fullWidth
                                                                            disabled={true}
                                                                        />
                                                                        {(shift?.requirementType === "home" || shift?.requirementType === "contract") &&
                                                                            <div className="flex-1">
                                                                                <Field
                                                                                    //   disabled={!mode ? true : false}
                                                                                    //   required
                                                                                    inputClass="multiple-date-box"
                                                                                    className="rmdp-mobile"
                                                                                    plugins={[<DatePanel
                                                                                        eachDaysInRange/>]}
                                                                                    format="MM/DD/YYYY"
                                                                                    multiple={true}
                                                                                    //  onChange={handleDatePicker}
                                                                                    //  value={value}
                                                                                    variant="inline"
                                                                                    inputVariant="outlined"
                                                                                    placeholder={
                                                                                        "Select Single (or) Multiple Dates"
                                                                                    }
                                                                                    id="input_shift_requirement_shift_datepicker"
                                                                                    name="shift_dates"
                                                                                    InputLabelProps={{shrink: true}}
                                                                                    component={DatePickers}
                                                                                    style={{width: '269.33px'}}
                                                                                    onChange={(dates: any) => {
                                                                                        const shift_dates = dates.map((item: any) => {
                                                                                            let mm = item?.month?.number.toString().padStart(2, '0');
                                                                                            let dd = item?.day.toString().padStart(2, '0');
                                                                                            let yyyy = item?.year;

                                                                                            // Format using moment with explicit UTC handling
                                                                                            return moment.utc(`${yyyy}-${mm}-${dd}`).format('YYYY-MM-DD');
                                                                                        });

                                                                                        // Update the Formik state with the formatted dates
                                                                                        setFieldValue(`requirements[${index}].shift_dates`, shift_dates);
                                                                                    }}
                                                                                />
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {shift?.requirementType === "contract" &&
                                                            <div>
                                                                <h3>
                                                                    {shift?.hcp_first_name} {shift?.hcp_last_name} (
                                                                    {shift?.facility_name || "N/A"})
                                                                    (Contract /
                                                                    Travel)
                                                                    ({CommonService.formatDate(shift?.start_date) + "-" + CommonService.formatDate(shift?.end_date)})
                                                                </h3>
                                                                <div className="input-container mrg-top-10">
                                                                    <div className="flex-1">
                                                                        <Field
                                                                            //   disabled={!mode ? true : false}
                                                                            //   required
                                                                            inputClass="multiple-date-box"
                                                                            className="rmdp-mobile"
                                                                            plugins={[<DatePanel eachDaysInRange/>]}
                                                                            format="MM/DD/YYYY"
                                                                            multiple={true}
                                                                            //  onChange={handleDatePicker}
                                                                            //  value={value}
                                                                            variant="inline"
                                                                            inputVariant="outlined"
                                                                            placeholder={
                                                                                "Select Single (or) Multiple Dates"
                                                                            }
                                                                            id="input_shift_requirement_shift_datepicker"
                                                                            name="shift_dates"
                                                                            InputLabelProps={{shrink: true}}
                                                                            component={DatePickers}
                                                                            style={{width: '269.33px'}}
                                                                            onChange={(dates: any) => {
                                                                                const shift_dates = dates.map((item: any) => {
                                                                                    let mm = item?.month?.number.toString().padStart(2, '0');
                                                                                    let dd = item?.day.toString().padStart(2, '0');
                                                                                    let yyyy = item?.year;

                                                                                    // Format using moment with explicit UTC handling
                                                                                    return moment.utc(`${yyyy}-${mm}-${dd}`).format('YYYY-MM-DD');
                                                                                });

                                                                                // Update the Formik state with the formatted dates
                                                                                setFieldValue(`requirements[${index}].shift_dates`, shift_dates);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {/*<Field*/}
                                                                    {/*    variant="outlined"*/}
                                                                    {/*    name={`requirements.${index}.start_date`}*/}
                                                                    {/*    type="date"*/}
                                                                    {/*    InputLabelProps={{shrink: true}}*/}
                                                                    {/*    component={TextField}*/}
                                                                    {/*    label="Start Date* (MM/DD/YYYY)"*/}
                                                                    {/*    fullWidth*/}
                                                                    {/*    disabled={true}*/}
                                                                    {/*    parse={(value: any) => formatDateForInput(value)}*/}
                                                                    {/*/>*/}


                                                                    {/*<Field*/}
                                                                    {/*    disabled={true}*/}
                                                                    {/*    variant="inline"*/}
                                                                    {/*    orientation="landscape"*/}
                                                                    {/*    openTo="date"*/}
                                                                    {/*    format="MM/dd/yyyy"*/}
                                                                    {/*    views={["year", "month", "date"]}*/}
                                                                    {/*    inputVariant="outlined" component={DatePicker}*/}
                                                                    {/*    placeholder="MM/DD/YYYY" fullWidth*/}
                                                                    {/*    autoComplete="off"*/}
                                                                    {/*    InputLabelProps={{shrink: true}}*/}
                                                                    {/*    label="Start Date* (MM/DD/YYYY)"*/}
                                                                    {/*    name={`requirements.${index}.start_date`}/>*/}
                                                                    <Field
                                                                        name={`requirements.${index}.start_time`}
                                                                        variant="outlined"
                                                                        type="time"
                                                                        InputLabelProps={{shrink: true}}
                                                                        component={TextField}
                                                                        label="Start Time*"
                                                                        fullWidth
                                                                        //  disabled={true}
                                                                    />
                                                                    <Field
                                                                        name={`requirements.${index}.end_time`}
                                                                        variant="outlined"
                                                                        type="time"
                                                                        InputLabelProps={{shrink: true}}
                                                                        component={TextField}
                                                                        label="End Time*"
                                                                        fullWidth
                                                                        // disabled={true}
                                                                    />
                                                                    {/*<Field*/}
                                                                    {/*    name={`requirements.${index}.differential_amount`}*/}
                                                                    {/*    variant="outlined"*/}
                                                                    {/*    InputLabelProps={{shrink: true}}*/}
                                                                    {/*    type={"text"}*/}
                                                                    {/*    component={TextField}*/}
                                                                    {/*    label="Differential Amount*"*/}
                                                                    {/*    fullWidth*/}
                                                                    {/*/>*/}
                                                                </div>
                                                            </div>}
                                                        {shift?.requirementType === "home" &&
                                                            <div>
                                                                <h3>
                                                                    {shift?.hcp_first_name} {shift?.hcp_last_name} (
                                                                    {shift?.facility_name || "N/A"})
                                                                    (Per Visit
                                                                    Shift) (Visit Patient
                                                                    Date:-{CommonService.formatDate(shift?.end_date)})
                                                                </h3>
                                                                <div className="input-container mrg-top-10">
                                                                    <div className="flex-1">
                                                                        <Field
                                                                            //   disabled={!mode ? true : false}
                                                                            //   required
                                                                            inputClass="multiple-date-box"
                                                                            className="rmdp-mobile"
                                                                            plugins={[<DatePanel eachDaysInRange/>]}
                                                                            format="MM/DD/YYYY"
                                                                            multiple={true}
                                                                            //  onChange={handleDatePicker}
                                                                            //  value={value}
                                                                            variant="inline"
                                                                            inputVariant="outlined"
                                                                            placeholder={
                                                                                "Select Visit Date"
                                                                            }
                                                                            id="input_shift_requirement_shift_datepicker"
                                                                            name="shift_dates"
                                                                            InputLabelProps={{shrink: true}}
                                                                            component={DatePickers}
                                                                            style={{width: '269.33px'}}
                                                                            onChange={(dates: any) => {
                                                                                const shift_dates = dates.map((item: any) => {
                                                                                    let mm = item?.month?.number.toString().padStart(2, '0');
                                                                                    let dd = item?.day.toString().padStart(2, '0');
                                                                                    let yyyy = item?.year;

                                                                                    // Format using moment with explicit UTC handling
                                                                                    return moment.utc(`${yyyy}-${mm}-${dd}`).format('YYYY-MM-DD');
                                                                                });

                                                                                // Update the Formik state with the formatted dates
                                                                                setFieldValue(`requirements[${index}].shift_dates`, shift_dates);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {/*<Field*/}
                                                                    {/*    variant="outlined"*/}
                                                                    {/*    name={`requirements.${index}.start_date`}*/}
                                                                    {/*    type="date"*/}
                                                                    {/*    InputLabelProps={{shrink: true}}*/}
                                                                    {/*    component={TextField}*/}
                                                                    {/*    label="Start Date* (MM/DD/YYYY)"*/}
                                                                    {/*    fullWidth*/}
                                                                    {/*    disabled={true}*/}
                                                                    {/*    parse={(value: any) => formatDateForInput(value)}*/}
                                                                    {/*/>*/}


                                                                    {/*<Field*/}
                                                                    {/*    disabled={true}*/}
                                                                    {/*    variant="inline"*/}
                                                                    {/*    orientation="landscape"*/}
                                                                    {/*    openTo="date"*/}
                                                                    {/*    format="MM/dd/yyyy"*/}
                                                                    {/*    views={["year", "month", "date"]}*/}
                                                                    {/*    inputVariant="outlined" component={DatePicker}*/}
                                                                    {/*    placeholder="MM/DD/YYYY" fullWidth*/}
                                                                    {/*    autoComplete="off"*/}
                                                                    {/*    InputLabelProps={{shrink: true}}*/}
                                                                    {/*    label="Start Date* (MM/DD/YYYY)"*/}
                                                                    {/*    name={`requirements.${index}.start_date`}/>*/}
                                                                    <Field
                                                                        name={`requirements.${index}.start_time`}
                                                                        variant="outlined"
                                                                        type="time"
                                                                        InputLabelProps={{shrink: true}}
                                                                        component={TextField}
                                                                        label="Start Time*"
                                                                        fullWidth
                                                                        //  disabled={true}
                                                                    />
                                                                    <Field
                                                                        name={`requirements.${index}.end_time`}
                                                                        variant="outlined"
                                                                        type="time"
                                                                        InputLabelProps={{shrink: true}}
                                                                        component={TextField}
                                                                        label="End Time*"
                                                                        fullWidth
                                                                        // disabled={true}
                                                                    />
                                                                    {/*<Field*/}
                                                                    {/*    name={`requirements.${index}.differential_amount`}*/}
                                                                    {/*    variant="outlined"*/}
                                                                    {/*    InputLabelProps={{shrink: true}}*/}
                                                                    {/*    type={"text"}*/}
                                                                    {/*    component={TextField}*/}
                                                                    {/*    label="Differential Amount*"*/}
                                                                    {/*    fullWidth*/}
                                                                    {/*/>*/}
                                                                </div>
                                                            </div>}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            />

                            <div className="create-shift-actions mrg-top-10">
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color={"primary"}
                                    id="create-shift-cancel-btn"
                                    onClick={() => cancel()}
                                >
                                    Back
                                </Button>
                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    id="btn_hcp_edit_submit"
                                    size="large"
                                    variant={"contained"}
                                    color={"primary"}
                                    className={isSubmitting ? "normal has-loading-spinner" : "normal"}
                                >
                                    {!isSubmitting ? "CREATE" : "CREATING"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    );
};

export default AddShiftsComponent;
