import {PerDiemAddShiftsValidation} from "../../AddShiftsValidation";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import {Button, MenuItem} from "@material-ui/core";
import {ENV} from "../../../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {ApiService, CommonService} from "../../../../../helpers";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePickers from "react-multi-date-picker";

interface PerDiemProps {
    facilityId?: any
    shiftTimings?: any
}

interface ContactShiftItem {
    title: any,
    hcp_id: any,
    start_time: any,
    end_time: any,
    shift_type: any,
    shift_dates: any,
    shift_details: any,
}

let contractShiftInitialState: ContactShiftItem = {
    title: "",
    hcp_id: "",
    start_time: "",
    end_time: "",
    shift_type: "",
    shift_dates: [],
    shift_details: "",
};
const showDropDownBelowField: any = {
    MenuProps: {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        getContentAnchorEl: null,
    },
};

const PerDiemApprovedShiftCreationComponent = (props: PerDiemProps) => {
    const {facilityId, shiftTimings} = props;
    const [approvedHCPs, setApprovedHCPs] = useState([]);
    const [value, setValue] = useState<any>(null);
    const history = useHistory();

    function handleDatePicker(value: any) {
        setValue(value);
    }


    const formatShiftTimings = useCallback((item: any) => {
        let start = moment(CommonService.convertMinsToHrsMins(item?.shift_start_time), "hh:mm").format("LT");
        let end = moment(CommonService.convertMinsToHrsMins(item?.shift_end_time), "hh:mm").format("LT");
        let type = item?.shift_type;

        return `${start} - ${end} (${type}-Shift)`;
    }, []);

    const onPerDiemAdd = (data: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<any>) => {
        let shift_dates = value.map((item: any) => {
            let mm = item?.month?.number;
            let dd = item?.day;
            let yyyy = item?.year;

            let shift_date = moment(`${yyyy}-${mm}-${dd}`).format("YYYY-MM-DD");
            return shift_date;
        });
        let payload: any = {
            ...data,
            shift_dates: shift_dates,
            type: "facility",
            facility_id: facilityId
        }
        console.log(payload)
        ApiService.upload(ENV.API_URL + "requirement/facility/preApprovedShifts", payload)
            .then((res) => {
                resetForm();
                setValue(null);
                CommonService.showToast(res.message || "Success", "success");
                history.push("/approvedShifts/list");
            })
            .catch((err) => {
                //   CommonService.showToast(err?.msg || "Error", "error");
            });


    };

    const getAllApprovedHcps = useCallback(() => {
        let payload: any = {
            is_approved: true,
        };
        CommonService._api
            .post(ENV.API_URL + "hcp/lite", payload)
            .then((resp) => {
                const approvedHcps = resp?.data?.map((item: any) => ({
                    name: `${item?.first_name} ${item?.last_name}`,
                    code: item?._id,
                }));
                setApprovedHCPs(approvedHcps || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getAllApprovedHcps()
    }, [getAllApprovedHcps]);


    return (
        <>
            <div className={'contract-travel-approved-shift-component'}>
                <Formik
                    onSubmit={onPerDiemAdd} initialValues={contractShiftInitialState}
                    validationSchema={PerDiemAddShiftsValidation}>
                    {({isSubmitting, isValid, resetForm, handleChange, setFieldValue, values}) => (
                        <Form className={"form-holder"} id="shift-add-form">
                            <div>
                                {/*<div className="shift-first-row shift-row ">*/}
                                {/*    <Field*/}
                                {/*        id="input_shift_requirement_title"*/}
                                {/*        variant="outlined"*/}
                                {/*        name="title"*/}
                                {/*        component={TextField}*/}
                                {/*        label="Title (30 characters)"*/}
                                {/*        fullWidth*/}
                                {/*        inputProps={{*/}
                                {/*            maxLength: 30,*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className="d-flex shift-third-row shift-row mrg-top-30 ">
                                    <div className="shift-calender">
                                        <Field
                                            required
                                            inputClass="custom-input"
                                            className="rmdp-mobile"
                                            plugins={[<DatePanel eachDaysInRange/>]}
                                            format="MM/DD/YYYY"
                                            // range={mode === "range" ? true : false}
                                            multiple={true}
                                            onChange={handleDatePicker}
                                            value={value}
                                            variant="inline"
                                            inputVariant="outlined"
                                            placeholder={"Date(s)*"}
                                            id="input_shift_requirement_shift_datepicker"
                                            name="shift_dates"
                                            InputLabelProps={{shrink: true,}}
                                            component={DatePickers}
                                        />
                                        {/*<DateRangeOutlined*/}
                                        {/*    style={handleShowHideCalender()}*/}
                                        {/*    className="date-icon"*/}
                                        {/*    fontSize="large"*/}
                                        {/*    color="action"*/}
                                        {/*/>*/}
                                    </div>

                                    <div className="shift-calender">
                                        <Field
                                            SelectProps={showDropDownBelowField}
                                            id="input_shift_requirement_shift_timings"
                                            variant="outlined"
                                            select
                                            required
                                            name="shift_timings"
                                            component={TextField}
                                            label="Shift Timings and Type"
                                            fullWidth
                                            onChange={(e: any) => {
                                                const selectedShiftTiming = e.target.value;
                                                if (shiftTimings.length > 0) {
                                                    setFieldValue("start_time", selectedShiftTiming?.shift_start_time);
                                                    setFieldValue("end_time", selectedShiftTiming?.shift_end_time);
                                                    setFieldValue("shift_type", selectedShiftTiming?.shift_type);
                                                }
                                            }}
                                        >
                                            <MenuItem value="">Select Shift Timing</MenuItem>
                                            {shiftTimings.length > 0 &&
                                                shiftTimings?.map((item: any, index: any) => {
                                                    let shift = formatShiftTimings(item);
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {shift}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Field>

                                    </div>


                                </div>
                                <div className="d-flex shift-third-row shift-row mrg-top-30 ">

                                    <div className="shift-calender">
                                        <Field
                                            SelectProps={showDropDownBelowField}
                                            id="input_shift_requirement_shift_timings"
                                            variant="outlined"
                                            select
                                            required
                                            name="hcp_id"
                                            component={TextField}
                                            label="Staff"
                                            fullWidth
                                            onChange={(e: any) => {
                                                setFieldValue("hcp_id", e.target.value);
                                            }}
                                        >
                                            {/*<MenuItem value="">Select Shift Timing</MenuItem>*/}
                                            {approvedHCPs.length > 0 &&
                                                approvedHCPs?.map((item: any, index: any) => {
                                                    return (
                                                        <MenuItem value={item?.code} key={index}>
                                                            {item?.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Field>

                                    </div>

                                    <div className="shift-calender">
                                        <Field
                                            id="input_shift_requirement_title"
                                            variant="outlined"
                                            name="shift_rate"
                                            component={TextField}
                                            label="Pay Rate ($)"
                                            fullWidth
                                            inputProps={{
                                                maxLength: 30,
                                            }}
                                        />
                                    </div>


                                </div>


                                <div className="shift-third-row  mrg-top-30">
                                    <Field
                                        id="input_shift_requirement_shift_details"
                                        label="Shift Requirement Details*"
                                        placeholder="Type Shift Details Here"
                                        variant="outlined"
                                        component={TextField}
                                        type={"text"}
                                        name="shift_details"
                                        fullWidth
                                        multiline
                                        minRows={3}
                                        onChange={(e: any) => {
                                            setFieldValue("shift_details", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={'d-flex justify-content-center mrg-top-20'}>
                                <Button
                                    type="submit"
                                    id="btn_add_shift_requirement_save"
                                    variant={"contained"}
                                    className={"normal"}
                                    color={"primary"}
                                >
                                    Create Approved Shift
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}
export default PerDiemApprovedShiftCreationComponent
